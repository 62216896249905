import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_SUBCONTRACTORS,
  DELETE_SUBCONTRACTOR,
  ADD_SUBCONTRACTOR,
  EDIT_SUBCONTRACTOR
} from "./actionTypes";

import {
  getSubcontractorsSuccess,
  getSubcontractors,
  getSubcontractorsFail,
  deleteSubcontractorModal
} from "./actions";

import {
  getSubcontractors as getSubcontractorsAPI,
  deleteSubcontractor as deleteSubcontractorAPI,
  addSubcontractor as addSubcontractorAPI,
  editSubcontractor as editSubcontractorAPI,
} from "api/v1";
import { getTenant } from "store/actions";


function* fetchSubcontractors({ payload: { params } }) {
  try {
    const response = yield call(getSubcontractorsAPI, params);
    yield put(getSubcontractorsSuccess(response));
  } catch (error) {
    yield put(getSubcontractorsFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addSubcontractor({ payload: { subcontractor, actions } }) {
  try {
    yield call(addSubcontractorAPI, subcontractor)
    toast.success("Subcontractor Created!");
    yield put(getSubcontractors(true));
    actions.resetForm()
    yield put(getTenant())

  } catch (error) {
    yield put(getSubcontractorsFail("Creation failed!"))
    let { errors } = error

    if (errors) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    if (error == 'Forbidden')
      yield put(getTenant())
    else
      toast.error("Something went wrong")
  }
}

function* editSubcontractor({ payload: { id, subcontractor, actions } }) {
  try {
    yield call(editSubcontractorAPI, id, subcontractor)
    toast.success("Subcontractor Updated!");
    yield put(getSubcontractors(true));
    yield put(getTenant())

  } catch (error) {
    let { errors } = error

    yield put(getSubcontractorsFail("Updation failed!"))
    if (errors && actions) {
      let { user } = errors
      actions.setErrors({ ...errors, ...user })

    }
    if (error == 'Forbidden')
      yield put(getTenant())
    else
      toast.error("Something went wrong")
  }
}

function* deleteSubcontractor({ payload: { subcontractor } }) {
  try {
    yield call(deleteSubcontractorAPI, subcontractor.id);
    put(deleteSubcontractorModal())
    yield put(getSubcontractors(true));
    yield put(getTenant())
    toast.warn("Subcontractor Deleted!");
  } catch (error) {
    yield put(getSubcontractorsFail(""))
    toast.error("Something went wrong!");
  }
}

function* subcontractorSaga() {
  yield takeEvery(GET_SUBCONTRACTORS, fetchSubcontractors);
  yield takeEvery(DELETE_SUBCONTRACTOR, deleteSubcontractor);
  yield takeEvery(ADD_SUBCONTRACTOR, addSubcontractor);
  yield takeEvery(EDIT_SUBCONTRACTOR, editSubcontractor);
}

export default subcontractorSaga;
