import React, { useEffect, useState, useRef, useCallback } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getSubcontractors as onGetSubcontractors,
  deleteSubcontractor as onDeleteSubcontractor,
  newSubcontractorModal,
  deleteSubcontractorModal,
  editSubcontractorModal,
  editSubcontractor
} from "store/subcontractors/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { isSeatAllowed, setLoader } from "utils/common";
import Create from './Create'
import Edit from './Edit'
import { getTenant } from "store/actions";

const Subcontractors = props => {
  const dispatch = useDispatch();

  const { subcontractorWithPagy, loading, deleteModal, tenant } = useSelector(state => ({
    subcontractorWithPagy: state.subcontractors.subcontractorWithPagy,
    loading: state.subcontractors.loading,
    deleteModal: state.subcontractors.delete,
    tenant: state.tenants.tenant
  }));
  let { results: subcontractors } = subcontractorWithPagy

  const [subcontractor, setSubcontractor] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
  })

  //pagination customization
  const pageOptions = {
    sizePerPage: subcontractorWithPagy.page_size,
    totalSize: subcontractorWithPagy.total,
    page: subcontractorWithPagy.page,
    custom: true,
  };

  const isActiveDisabled = useCallback((row) => {
    if (isSeatAllowed(tenant))
      return false

    return !row.user.is_active
  }, [tenant])

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "Id No.",
      dataField: "id_no",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id_no}</>,
    },
    {
      dataField: "avatar",
      text: "Avatar",
      sort: false,
      formatter: (cellContent, row) => (
        <>
          <div className="table-avatar">
            {
              row.avatar ? (
                <img
                  src={row.avatar}
                  alt={row.first_name[0]}
                />
              ) : (<span>{row.first_name[0]}</span>)
            }

          </div>
        </>
      ),
    },
    {
      dataField: "username",
      text: "Username",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.first_name} ${row.last_name}`}</p>
        </>
      ),
    },
    {
      text: "Email",
      dataField: "email",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.user.email}`}</p>
        </>
      ),
    },
    {
      dataField: "profession",
      text: "Profession",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{row.profession}</i></b>,
    },
    {
      text: "Active",
      dataField: "is_active",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <div className="form-check form-switch form-switch-md">
        <input
          type="checkbox"
          className="form-check-input"
          id="customSwitch2"
          disabled={isActiveDisabled(row)}
          defaultChecked={row.user.is_active}
          onClick={e => {
            dispatch(editSubcontractor(row.id, { user: { is_active: !row.user.is_active } }), null)
          }}
        />
      </div>
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, subcontractor) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => handleEditSubcontractor(subcontractor)}
          >
            Edit
          </MenuItem>
          {/* <MenuItem
            onClick={() => onClickDelete(subcontractor)}
          >
            Delete
          </MenuItem> */}
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete subcontractor

  const onClickDelete = (subcontractor) => {
    setSubcontractor(subcontractor);
    dispatch(deleteSubcontractorModal())
  };

  const handleDeleteSubcontractor = () => {
    dispatch(onDeleteSubcontractor(subcontractor))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(getTenant())
    dispatch(onGetSubcontractors(true));
  }

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetSubcontractors(true, { page, ...searchParams }));
  };

  const handleCreateSubcontractor = () => {
    dispatch(newSubcontractorModal())
  };

  const handleEditSubcontractor = subcontractor => {
    setSubcontractor(subcontractor)
    dispatch(editSubcontractorModal())
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];


  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetSubcontractors(false, _searchParams));
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSubcontractor}
        onCloseClick={() => dispatch(deleteSubcontractorModal())}
      />

      <Create />
      <Edit subcontractor={subcontractor} />

      <div className="page-content">
        <MetaTags>
          <title>Subcontractors</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Subcontractors" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={subcontractors}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={subcontractors || []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          searchText={searchParams.search}
                                          onSearch={text => handleSearch("search", text)} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        disabled={!isSeatAllowed(tenant)}
                                        color="success"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={handleCreateSubcontractor}
                                      >
                                        <i className="mdi mdi-plus me-1" />
                                        New Subcontractor
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        key={`availed-${tenant?.seats_availed || 0}`}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={"No Data found"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                <Row className="align-items-md-center mt-30">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Subcontractors.propTypes = {
  subcontractors: PropTypes.array,
  onGetSubcontractors: PropTypes.func,
  onAddNewSubcontractor: PropTypes.func,
  onDeleteSubcontractor: PropTypes.func,
  onUpdateSubcontractor: PropTypes.func,
};

export default Subcontractors;
