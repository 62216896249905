const { REACT_APP_BASE_URL } = process.env;
import { refreshToken } from 'api/auth/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { isSettingPage } from 'utils/common';

function subdomainHelper() {
  const subdomain = localStorage.getItem("subdomain")
  return `${REACT_APP_BASE_URL.substr(0, 8)}${subdomain}.${REACT_APP_BASE_URL.substr(8, REACT_APP_BASE_URL.length)}/api/v1`
}

function logout() {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  window.location.href = '/login';
  return Promise.reject(new Error('Session expired!'));
}

const service = axios.create({
  baseURL: new URL('/api/v1', REACT_APP_BASE_URL).href,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

function isAuthError(error) {
  const { response } = error;
  return response && response.status === 401;
}

function isSubscriptionError(error) {
  const { response } = error;
  return response && response.status === 402;
}

function isForbiddenError(error) {
  const { response } = error;
  return response && response.status === 403;
}

function isNotFound(error) {
  const { response } = error;
  return response && [422, 404].includes(response.status);
}

service.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token');
  const url = config.url.match(/\/$/) ? config.url : `${config.url}/`;

  if (token) {
    return {
      ...config,
      url,
      baseURL: config.disableSubdomain ? config.baseURL : subdomainHelper(),
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return {
    ...config,
    url,
    baseURL: config.disableSubdomain ? config.baseURL : subdomainHelper(),
    headers: config.headers,
  };
});

// Response Interceptor
service.interceptors.response.use(
  ({ data }) => {
    if (Array.isArray(data))
      return data
    else if (!data?.hasOwnProperty('is_tenant_active'))
      return data
    else if (!isSettingPage()) {
      if (!data.is_tenant_active)
        window.location.href = '/settings/subscriptions';

      return data.data
    }
    else
      return data.data
  },
  async (error) => {
    if (!error) {
      return Promise.reject(new Error('There was an error.'));
    }
    if (isAuthError(error)) {
      try {
        let { access_token } = await refreshToken()
        localStorage.setItem('access_token', access_token)
        return request(error.config)

      } catch (error) {
        logout()
      }
    }
    if (isNotFound(error)) {
      window.location.href = '/dashboard';
      return Promise.reject(new Error('Not found!'));
    }
    if (isSubscriptionError(error)) {
      window.location.href = '/settings/subscriptions';
      return Promise.reject(new Error('Subscription expired!'));
    }
    if (isForbiddenError(error)) {
      toast.error("You are not allowed to perform this action")
      return Promise.reject('Forbidden');
    }
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const { response = {} } = error;
    return Promise.reject(response.data || error);
  },
);

const cancelable = {};

export default function request({ cancelPrevious, ...config }) {
  if (cancelPrevious) {
    const key = `${config.method}-${config.url}`;
    const cancel = cancelable[key];
    if (cancel) cancel('request-cancel');
    return service({
      ...config,
      cancelToken: new axios.CancelToken((cancelRequest) => {
        cancelable[key] = cancelRequest;
      }),
    });
  }
  return service(config);
}
